import gql from 'graphql-tag'

export const FACILITY_REVENUE_REPORT = `
  query($fromDate: DateString!, $toDate: DateString!, $facilityId: Int!) {
    facilityRevenueReport(
      facilityId: $facilityId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      fromDatePrev #DateString!
      toDatePrev #DateString!
      occupancyRates {
        title
        occupancyRate
        count
        occupancyRatePrev
        countPrev
      }
      paymentAmounts {
        title
        amount
        amountPrev
      }
      chargeSummaries {
        type
        subject
        totalWithoutSalesTax
        totalSalesTax
        totalWithSalesTax
        count #Int!
        totalWithoutSalesTaxPrev
        totalSalesTaxPrev
        totalWithSalesTaxPrev
        countPrev
      }
    }
  }
`

export const FACILITY_REVENUE_REPORT_PDF = gql`
query (
  $fromDate: DateString!
  $toDate: DateString! 
  $facilityId: Int! 
) {
  facilityRevenueReportPdf(facilityId: $facilityId, fromDate: $fromDate, toDate: $toDate) {
    files {
      fileName
      downloadUrl 
    }
  }
}
`
