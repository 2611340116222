import gql from 'graphql-tag'

export const FACILITY_REVENUE_REPORT_CSV = gql`
  mutation($fromDate: DateString!, $toDate: DateString!, $facilityId: Int!) {
    facilityRevenueReportCsv(
      fromDate: $fromDate
      toDate: $toDate
      facilityId: $facilityId
    ) {
      files {
        fileName # String! ファイル名
        downloadUrl # String! ダウンロードURL
      }
    }
  }
`
