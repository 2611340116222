<template>
  <div>
    <div class="header-main">
      <h1 class="py-4 page-title-main">
        {{ $t('routeTitles.formReport.formReport') }}
      </h1>
    </div>
    <template>
      <div class="px-main">
        <v-tabs v-model="tab" class="t-tab-custom">
          <v-tab v-for="(item, i) in items" :key="i" :to="item.to">{{
            item.title
          }}</v-tab>
        </v-tabs>
        <slot name="default" />
      </div>
    </template>
  </div>
</template>

<script>
import { checkPermissionTab } from '@/utils/permissions'
export default {

  name: 'BaseFormReport',
  data () {
    return {
      tab: null,
      items: [],
      itemsTmp: [
        {
          title: this.$t('routeTitles.formReport.forIncomeReport'),
          to: '/form-report/income-report',
          pathRoot: '/form-report/income-report',
          order: 1
        },
        {
          title: this.$t('routeTitles.formReport.salesManagementTable'),
          to: '/form-report/sales-management',
          pathRoot: '/form-report/sales-management',
          order: 2
        },
        {
          title: this.$t('routeTitles.formReport.otherCSV'),
          to: '/form-report/other-csv',
          pathRoot: '/form-report/other-csv',
          order: 3
        },
        {
          title: this.$t('routeTitles.formReport.monthlyLodgingTaxSchedule'),
          to: '/form-report/lodging-tax-schedule',
          pathRoot: '/form-report/lodging-tax-schedule',
          order: 4
        }
      ]
    }
  },
  mounted () {
    this.checkPerTab()
  },
  methods: {
    checkPermissionTab,
    checkPerTab () {
      let itemCheck = this.checkPermissionTab(this.itemsTmp)
      itemCheck = [...new Set(itemCheck)]
      itemCheck.sort((a, b) => a.order - b.order)
      this.items = itemCheck
    }
  }
}
</script>

<style lang="scss" scoped>
.v-tab{
  font-size: 16px !important;
  font-weight: 600 !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active){
  color: #aaa !important;
}
</style>>
