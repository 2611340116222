var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-form-report',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(_vm._s(_vm.$route.name))]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"my-5"},[_c('div',{staticClass:"flex-header"},[_c('DatePickerSelectFromTo',{attrs:{"dates":_vm.datePicker,"dateFrom":_vm.datePicker[0].date,"dateTo":_vm.datePicker[1].date,"attrTagInput":{
            small: true,
            class: 'btn--date mr-5',
            type: 'btn'
          },"inline":true,"funFormatTitle":_vm.formatDate,"icon":true},on:{"change":_vm.changeDate,"reset":_vm.changeDate}}),_vm._v(" "+_vm._s(_vm.$t("common.facility"))+" "),_c('v-select',{staticClass:"v-select-custom ml-1 facility-list",attrs:{"items":_vm.facilityList,"item-text":"name","item-value":"id","dense":"","small":"","outlined":"","color":"var(--text__gray)","hide-details":""},on:{"change":_vm.fetchData},model:{value:(_vm.facilitySelected),callback:function ($$v) {_vm.facilitySelected=$$v},expression:"facilitySelected"}})],1),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"d-flex text--small"},[_vm._v(" "+_vm._s(_vm.$t("formReport.comparisonPeriodStart"))+" "),_c('span',{staticClass:"ml-3 mr-8 font-size-text"},[_vm._v(_vm._s(_vm.formatDate(_vm.fromDateData)))]),_vm._v(" "+_vm._s(_vm.$t("formReport.comparisonPeriodEnd"))+" "),_c('span',{staticClass:"ml-3 font-size-text"},[_vm._v(_vm._s(_vm.formatDate(_vm.toDateData)))])]),_c('div',{staticClass:"text-right mb-3"},[_c('v-btn',{staticClass:"font-size-button",attrs:{"disabled":!_vm.checkPerUser,"color":"var(--text__primary)","outlined":""},on:{"click":function($event){return _vm.showPdf()}}},[_vm._v(" "+_vm._s(_vm.$t("buttons.printing"))+" ")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-data-table',{staticClass:"table-custom elevation-1",attrs:{"headers":_vm.occupancyRatesHeader,"items":_vm.getOccupancyRates,"hide-default-footer":"","no-data-text":_vm.$t('rules.noData'),"disable-pagination":""},scopedSlots:_vm._u([{key:"item.occupancyRate",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.occupancyRate == 0 ? 0 : (item.occupancyRate * 100).toFixed(2))+"% ")]}},{key:"item.occupancyRatePrev",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.occupancyRatePrev == 0 ? 0 : (item.occupancyRatePrev * 100).toFixed(2))+"% ")]}}],null,true)})],1),_c('v-col',{staticClass:"v-col",attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"elevation-1"},[_c('v-simple-table',{staticClass:"table-custom",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v(" "+_vm._s(_vm.$t("formReport.salesPeriodDeposit"))+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$t("formReport.contrastPeriodDeposit"))+" ")])])]),_c('tbody',[(_vm.getPaymentAmounts.length > 0)?[_vm._l((_vm.getPaymentAmounts),function(item,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(item.title))]),_c('td',[_vm._v(_vm._s(item.amount))]),_c('td',[_vm._v(_vm._s(item.amountPrev))])])}),_c('tr',[_c('td',[_vm._v(_vm._s(_vm.$t("common.total")))]),_c('td',[_vm._v(" "+_vm._s(_vm.getPaymentAmountTotal())+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.getPaymentAmountPrevTotal())+" ")])])]:_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"3"}},[_vm._v(" "+_vm._s(_vm.$t("rules.noData"))+" ")])])],2)]},proxy:true}])})],1)])],1),_c('div',{staticClass:"text-right mt-10"},[_c('v-btn',{staticClass:"font-size-button",attrs:{"disabled":!_vm.checkPerUser,"color":"var(--text__primary)","outlined":""},on:{"click":_vm.facilityRevenueReportCsv}},[_vm._v(" "+_vm._s(_vm.$t("buttons.exportAsCSV"))+" ")])],1),_c('div',{staticClass:"elevation-1 mt-3"},[_c('v-data-table',{staticClass:"table-custom",attrs:{"headers":_vm.chargeSummariesHeader,"items":_vm.getChargeSummaries,"no-data-text":_vm.$t('rules.noData'),"disable-sort":"","hide-default-footer":"","disable-pagination":""}})],1)],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }