<template>
  <div>
    <!-- v-btn -->
    <div
      v-if="type === 'btn'"
      class="d-flex align-center mb-1"
      :style="reverseLabel ? 'flex-direction: row-reverse' : ''"
    >
      {{ label }}
      <v-icon v-if="icon">mdi-calendar-month</v-icon>
      <v-btn v-on="action" v-bind="{ ...attr }">
        <template v-if="date">
          {{ title }}
          <v-icon
            v-if="hasBtnResetDatePicker"
            @click="reset()"
            class="btn-mdi-close-custom"
            >mdi-close</v-icon
          >
        </template>
        <template v-else> 指定なし </template>
      </v-btn>
    </div>

    <!-- v-text-field -->
    <div
      v-if="type === 'text-field'"
      class="d-flex justify-start"
      :style="reverseLabel ? 'flex-direction: row-reverse' : ''"
    >
      <h5 class="text-12px mt-3 mr-3 ml-2">
        {{ label }}
      </h5>
      <v-text-field
        :value="date ? date : '指定なし'"
        v-bind="{ ...attr }"
        @click:append="reset()"
        v-on="action"
        class="btn-mdi-close-text-field-custom"
        :append-icon="hasBtnResetDatePicker && date ? 'mdi-close' : ''"
      >
      </v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShowDate',
  data () {
    return {}
  },
  props: {
    type: String,
    date: String,
    attr: Object,
    title: String,
    action: Object,
    hasBtnResetDatePicker: {
      default: false,
      type: Boolean
    },
    icon: {
      default: false,
      type: Boolean
    },
    label: String,
    reverseLabel: Boolean
  },
  methods: {
    reset () {
      this.$emit('reset')
    }
  }
}
</script>

<style scoped lang="scss">
</style>
