<template>
  <base-form-report>
    <template v-slot:title>{{ $route.name }}</template>
    <template v-slot:default>
      <div class="my-5">
        <div class="flex-header">
          <DatePickerSelectFromTo
            :dates="datePicker"
            :dateFrom="datePicker[0].date"
            :dateTo="datePicker[1].date"
            @change="changeDate"
            :attrTagInput="{
              small: true,
              class: 'btn--date mr-5',
              type: 'btn'
            }"
            :inline="true"
            :funFormatTitle="formatDate"
            :icon="true"
            @reset="changeDate"
          ></DatePickerSelectFromTo>
          {{ $t("common.facility") }}
          <v-select
            :items="facilityList"
            v-model="facilitySelected"
            @change="fetchData"
            item-text="name"
            item-value="id"
            dense
            small
            outlined
            color="var(--text__gray)"
            hide-details
            class="v-select-custom ml-1 facility-list"
          ></v-select>
        </div>

        <div class="mt-5">
          <div class="d-flex text--small">
            {{ $t("formReport.comparisonPeriodStart") }}
            <span class="ml-3 mr-8 font-size-text">{{
              formatDate(fromDateData)
            }}</span>
            {{ $t("formReport.comparisonPeriodEnd") }}
            <span class="ml-3 font-size-text">{{
              formatDate(toDateData)
            }}</span>
          </div>
          <div class="text-right mb-3">
            <v-btn
              :disabled="!checkPerUser"
              color="var(--text__primary)"
              class="font-size-button"
              outlined
              @click="showPdf()"
            >
              {{ $t("buttons.printing") }}
            </v-btn>
          </div>
          <v-row>
            <!-- LEFT TABLE -->
            <v-col cols="12" md="6">
              <v-data-table
                class="table-custom elevation-1"
                :headers="occupancyRatesHeader"
                :items="getOccupancyRates"
                hide-default-footer
                :no-data-text="$t('rules.noData')"
                disable-pagination
              >
                <template v-slot:[`item.occupancyRate`]="{ item }">
                  {{
                    item.occupancyRate == 0
                      ? 0
                      : (item.occupancyRate * 100).toFixed(2)
                  }}%
                </template>
                <template v-slot:[`item.occupancyRatePrev`]="{ item }">
                  {{
                    item.occupancyRatePrev == 0
                      ? 0
                      : (item.occupancyRatePrev * 100).toFixed(2)
                  }}%
                </template>
              </v-data-table>
            </v-col>
            <!-- END LEFT TABLE -->

            <!-- RIGHT TABLE -->
            <v-col class="v-col" cols="12" md="6">
              <div class="elevation-1">
                <v-simple-table class="table-custom">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th></th>
                        <th>
                          {{ $t("formReport.salesPeriodDeposit") }}
                        </th>
                        <th>
                          {{ $t("formReport.contrastPeriodDeposit") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <template v-if="getPaymentAmounts.length > 0">
                        <tr
                          v-for="(item, index) in getPaymentAmounts"
                          :key="index"
                        >
                          <td>{{ item.title }}</td>
                          <td>{{ item.amount }}</td>
                          <td>{{ item.amountPrev }}</td>
                        </tr>
                        <tr>
                          <td>{{ $t("common.total") }}</td>
                          <td>
                            {{ getPaymentAmountTotal() }}
                          </td>
                          <td>
                            {{ getPaymentAmountPrevTotal() }}
                          </td>
                        </tr>
                      </template>
                      <tr v-else>
                        <td class="text-center" colspan="3">
                          {{ $t("rules.noData") }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
            <!-- END RIGHT TABLE -->
          </v-row>

          <div class="text-right mt-10">
            <v-btn
              :disabled="!checkPerUser"
              color="var(--text__primary)"
              class="font-size-button"
              outlined
              @click="facilityRevenueReportCsv"
            >
              {{ $t("buttons.exportAsCSV") }}
            </v-btn>
          </div>
          <!-- TABLE BOTTOM -->
          <div class="elevation-1 mt-3">
            <v-data-table
              class="table-custom"
              :headers="chargeSummariesHeader"
              :items="getChargeSummaries"
              :no-data-text="$t('rules.noData')"
              disable-sort
              hide-default-footer
              disable-pagination
            ></v-data-table>
          </div>
        </div>
      </div>
    </template>
  </base-form-report>
</template>

<script>
import BaseFormReport from '../../../components/FormReport/BaseFormReport'
import { downloadFile } from '@/utils/downloadFile'
import {
  formatDate,
  handlErrorView,
  getCopyErrorTextView
} from '@/constants/functions'
import { addMonths } from '@/utils/handleDate'
import {
  FACILITY_REVENUE_REPORT,
  FACILITY_REVENUE_REPORT_PDF
} from '@/api/graphql/formReport/sales-management'
import { FACILITY_REVENUE_REPORT_CSV } from '@/api/graphql/csv/facilityRevenueReportCsv'
import { FACILITY_LIST } from '@/api/graphql/facility-settings'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import { mapMutations, mapGetters } from 'vuex'
import gql from 'graphql-tag'
import DatePickerSelectFromTo from '@/components/Input/datePicker/DatePickerSelectFromTo.vue'

export default {
  name: 'SalesManagementTable',
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      menuDatePicker: [],
      datePicker: [
        { label: '期間開始', id: 1, date: new Date().toISOString().substr(0, 10) },
        { label: '期間終了', id: 2, date: new Date().toISOString().substr(0, 10) }
      ],
      facilityList: [],
      facilitySelected: null,
      facilityId: null,

      fromDateData: new Date().toISOString().substr(0, 10),
      toDateData: new Date().toISOString().substr(0, 10),

      occupancyRatesHeader: [
        {
          text: '',
          value: 'title'
        },
        {
          text: this.$t('formReport.operatingRatio'),
          value: 'occupancyRate'
        },
        {
          text: this.$t('common.number'),
          value: 'count'
        },
        {
          text: this.$t('formReport.utilizationRateComparisons'),
          value: 'occupancyRatePrev'
        },
        {
          text: this.$t('formReport.contractNumber'),
          value: 'countPrev'
        }
      ],
      paymentAmountsHeader: [],
      chargeSummariesHeader: [
        { text: this.$t('common.subItem'), value: 'type' },
        { text: this.$t('common.subjectName'), value: 'subject' },
        {
          text: this.$t('formReport.salesAmount'),
          value: 'totalWithoutSalesTax'
        },
        { text: this.$t('formReport.consumptionTax'), value: 'totalSalesTax' },
        {
          text: this.$t('formReport.taxIncludedSales'),
          value: 'totalWithSalesTax'
        },
        { text: this.$t('formReport.numOfEvents'), value: 'count' },
        {
          text: this.$t('formReport.comparisonSalesAmount'),
          value: 'totalWithoutSalesTaxPrev'
        },
        {
          text: this.$t('formReport.interpersonalconsumptionTax'),
          value: 'totalSalesTaxPrev'
        },
        {
          text: this.$t('formReport.salesIncludingTax'),
          value: 'totalWithSalesTaxPrev'
        },
        {
          text: this.$t('formReport.numberOfComparisons'),
          value: 'countPrev'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'getOccupancyRates',
      'getPaymentAmounts',
      'getChargeSummaries'
    ])
  },
  async mounted () {
    await this.getFacilityList()
    this.fetchData()
  },
  methods: {
    getCopyErrorTextView,
    ...mapMutations([
      'setCopyErrorText',
      'setLoadingOverlayHide',
      'setLoadingOverlayShow',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser',
      'setOccupancyRates',
      'setPaymentAmounts',
      'setChargeSummaries'
    ]),
    formatDate,
    addMonths,
    downloadFile,
    async getFacilityList () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`
            ${FACILITY_LIST}
          `,
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.setLoadingOverlayHide()
          this.facilityList = data.data.facilityList
          this.facilitySelected = data.data.facilityList[0].id
        })
        .catch((error) => {
          this.setLoadingOverlayHide()
          console.error(error)
        })
    },
    async facilityRevenueReportCsv () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .mutate({
          mutation: FACILITY_REVENUE_REPORT_CSV,
          variables: {
            fromDate: this.datePicker[0].date,
            toDate: this.datePicker[1].date,
            facilityId: parseInt(this.facilitySelected)
          },
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          const urlList = data.data.facilityRevenueReportCsv.files
          this.setLoadingOverlayHide()
          if (urlList.length > 0) {
            for (let i = 0; i < urlList.length; i++) {
              this.downloadFile(urlList[i].downloadUrl, urlList[i].fileName)
            }
          }
        })
        .catch((error) => {
          this.setLoadingOverlayHide()
          console.error(error)
        })
    },

    async changeDate (item) {
      if (item.id === 1) {
        this.datePicker[0].date = item.date
      } else {
        this.datePicker[1].date = item.date
      }
      await this.fetchData()
    },

    async showFile (urlPdf, namePdf) {
      fetch(urlPdf, {
        headers: {
          'Content-Type': 'application/pdf'
        }
      })
        .then((r) => r.blob())
        .then((res) => {
          this.showFile1(res, namePdf)
        })
    },

    showFile1 (blob, namePdf) {
      const newBlob = new Blob([blob], { type: 'application/pdf' })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)
        return
      }

      const data = window.URL.createObjectURL(newBlob)
      var link = document.createElement('a')
      link.href = data
      link.download = namePdf
      link.click()
      setTimeout(function () {
        window.URL.revokeObjectURL(data)
      }, 100)
    },

    async showPdf () {
      this.setLoadingOverlayShow()
      await this.$apollo
        .query({
          query: gql`
            ${FACILITY_REVENUE_REPORT_PDF}
          `,
          variables: {
            fromDate: this.datePicker[0].date,
            toDate: this.datePicker[1].date,
            facilityId: this.facilityId
          },
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.setLoadingOverlayHide()
          this.showFile(
            data.data.facilityRevenueReportPdf.files[0].downloadUrl,
            data.data.facilityRevenueReportPdf.files[0].fileName
          )
        })
        .catch((error) => {
          this.setLoadingOverlayHide()
          console.error(error)
        })

      this.fetchData()
    },
    async fetchData () {
      this.setLoadingOverlayShow()
      this.setOccupancyRates([])
      this.setPaymentAmounts([])
      this.setChargeSummaries([])
      const variables = {
        facilityId: parseInt(this.facilitySelected),
        fromDate: this.datePicker[0].date,
        toDate: this.datePicker[1].date
      }
      await this.$apollo
        .query({
          query: gql`
            ${FACILITY_REVENUE_REPORT}
          `,
          variables: variables,
          fetchPolicy: 'no-cache'
        })
        .then((data) => {
          this.setLoadingOverlayHide()
          this.fromDateData = data.data.facilityRevenueReport.fromDatePrev
          this.toDateData = data.data.facilityRevenueReport.toDatePrev
          this.facilityId = this.facilitySelected
          this.setOccupancyRates(
            data.data.facilityRevenueReport.occupancyRates
          )
          this.setPaymentAmounts(
            data.data.facilityRevenueReport.paymentAmounts
          )
          this.setChargeSummaries(
            data.data.facilityRevenueReport.chargeSummaries
          )
        })
        .catch(async (error) => {
          this.setLoadingOverlayHide()
          this.setCopyErrorText(
            this.getCopyErrorTextView(
              FACILITY_REVENUE_REPORT,
              variables,
              error.graphQLErrors
            )
          )
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser
          )
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
        })
    },
    getPaymentAmountTotal () {
      let total = 0
      if (this.getPaymentAmounts.length > 0) {
        total = this.getPaymentAmounts.reduce((n, { amount }) => n + amount, 0)
      }
      return total
    },
    getPaymentAmountPrevTotal () {
      let total = 0
      if (this.getPaymentAmounts.length > 0) {
        total = this.getPaymentAmounts.reduce(
          (n, { amountPrev }) => n + amountPrev,
          0
        )
      }
      return total
    }
  },
  components: {
    BaseFormReport,
    DatePickerSelectFromTo
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-data-table {
    max-height: 98vh;
    overflow-y: auto;
  }
  .text--small {
    font-size: 10px !important;
  }
  .v-data-table {
    tbody tr td {
      color: #757575 !important;
    }
    .v-data-table__wrapper table thead tr th {
      color: #757575 !important;
    }
  }
}
.font-size-text {
  font-size: 10px;
}
.font-size-button {
  font-size: 16px;
}
</style>
